import "core-js/modules/es.number.to-fixed.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import ButtonDialog from "@/components/ButtonDialog";
export default {
  name: "ResourceManageView",
  components: {
    BatterTableDataView: BatterTableDataView,
    ButtonDialog: ButtonDialog
  },
  data: function data() {
    return {
      tab: 'KET'
    };
  },
  methods: {
    fresh: function fresh() {
      this.$refs.ket.fetchData();
      this.$refs.pet.fetchData();
      this.$refs.fce.fetchData();
    },
    onDetailData: function onDetailData(d) {
      window.open(d.fileSource);
    },
    formatFileSize: function formatFileSize(fileSizeInBytes) {
      if (fileSizeInBytes < 1024) {
        return fileSizeInBytes + "B";
      } else if (fileSizeInBytes < 1024 * 1024) {
        return (fileSizeInBytes / 1024).toFixed(2) + " KB";
      } else if (fileSizeInBytes < 1024 * 1024 * 1024) {
        return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
      } else {
        return (fileSizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
      }
    },
    init: function init() {}
  },
  mounted: function mounted() {
    this.init();
  }
};