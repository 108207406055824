import _createForOfIteratorHelper from "/root/workspace/cambridge-admin_qoOL/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import api from "@/api";
export default {
  name: "DynaQueryForm",
  props: {
    buttonAreaWidth: {
      type: Number,
      "default": 24
    },
    formId: {
      type: Number,
      "default": 0
    },
    title: {
      type: String,
      "default": '搜索条件'
    },
    initQuery: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      colWidth: 12,
      form: [],
      query: {},
      dataSource: {},
      dataSourceMap: {},
      clearKeys: {},
      addButton: false
    };
  },
  methods: {
    fnLazyLoad: function fnLazyLoad(itemKey, source, node, resolve) {
      var query = {};
      if (node.level > 0) {
        query[itemKey] = node.value;
      }
      api.get(source, query).then(function (ret) {
        if (node.level >= 3) {
          //只到达社区级
          var _iterator = _createForOfIteratorHelper(ret),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var r = _step.value;
              r.leaf = true;
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
        resolve(ret);
      });
    },
    onReset: function onReset() {
      this.query = {};
      this.$emit('onReset');
    },
    addMore: function addMore() {
      this.$emit('addMore');
    },
    queryDataSource: function queryDataSource(dataKey) {
      var _this = this;
      // console.log('current',this.query);
      if (dataKey && this.clearKeys[dataKey]) {
        //需要清除其他key的情形
        var _iterator2 = _createForOfIteratorHelper(this.clearKeys[dataKey]),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var cl = _step2.value;
            this.query[cl] = null;
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
      var _loop = function _loop() {
        var k = _Object$keys[_i];
        if (dataKey && dataKey === k) {
          //当前key不更新
          return "continue";
        }
        api.get(_this.dataSourceMap[k], _this.query).then(function (ret) {
          _this.dataSource[k] = ret;
        });
      };
      for (var _i = 0, _Object$keys = Object.keys(this.dataSourceMap); _i < _Object$keys.length; _i++) {
        var _ret = _loop();
        if (_ret === "continue") continue;
      }
    },
    submit: function submit() {
      this.$emit('onQuery', this.query);
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    if (this.formId < 1) {
      return;
    }
    api.getQueryForm(this.formId, this.initQuery).then(function (ret) {
      _this2.form = ret.form;
      _this2.colWidth = ret.colWidth;
      _this2.addButton = ret.addButton;
      var _iterator3 = _createForOfIteratorHelper(ret.form),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var f = _step3.value;
          if (f['dataSource']) {
            _this2.dataSourceMap[f.name] = f.dataSource;
          }
          if (f['clearKeys']) {
            _this2.clearKeys[f.name] = f.clearKeys;
          }
          if (f.type === 'datepicker') {
            if (f.value) {
              _this2.query[f.name] = f.value;
            }
          }
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
      _this2.queryDataSource();
    });
  }
};