import axios from "../utils/http";
import path from "./path"

const stringFormat = (formatted, args) => {
    for (let i = 0; i < args.length; i++) {
        let regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
    }
    return formatted
}

const api = {
    getOssKeySecret() {
        return axios.get(path.baseUrl + path.getOssKeySecret)
    },
    getQueryForm(formId, query) {
        return axios.get(path.baseUrl + path.queryForm[formId], {params: query})
    },
    get(url, query) {
        if (query) {
            return axios.get(path.baseUrl + url, {params: query})
        }
        return axios.get(path.baseUrl + url)
    },
    post(url, data) {
        return axios.post(path.baseUrl + url, data)
    },
    getDialogForm(dataId, bindId) {
        if (bindId != null) {
            return axios.get(path.baseUrl + stringFormat(path.dialogs[dataId], [bindId]))
        }
        return axios.get(path.baseUrl + path.dialogs[dataId])
    },
    getMenus() {
        return axios.get(path.baseUrl + path.menus)
    },
    getUserInfo() {
        return axios.get(path.baseUrl + path.userInfo)
    },
    getLoginCaptchaToken() {
        return axios.get(path.baseUrl + path.loginCaptchaToken)
    },
    login(data) {
        return axios.post(path.baseUrl + path.login, data)
    },
    logout() {
        return axios.get(path.baseUrl + path.logout)
    },
    updateVip(query) {
        return axios.get(path.baseUrl + path.updateVip, {params: query})
    },
    updateUserVip(query) {
        return axios.get(path.baseUrl + path.updateUserVip, {params: query})
    }
}

export default api;