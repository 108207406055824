import _objectSpread from "/root/workspace/cambridge-admin_qoOL/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import api from "@/api";
import { mapMutations } from "vuex";
// import md5 from 'js-md5'

export default {
  name: "LoginPage",
  data: function data() {
    return {
      loginMode: 0,
      loginModel: {
        account: null,
        password: null
      },
      passwordError: null,
      accountError: null
    };
  },
  methods: _objectSpread(_objectSpread({}, mapMutations(['updateUser'])), {}, {
    doLogin: function doLogin() {
      var _this = this;
      var verify = true;
      if (!this.loginModel.account) {
        this.accountError = '账号必填';
        verify = false;
      }
      if (!this.loginModel.password) {
        this.passwordError = '密码必填';
        verify = false;
      }
      if (!verify) {
        return;
      }
      this.accountError = null;
      this.passwordError = null;
      api.login({
        username: this.loginModel.account,
        // password: md5(this.loginModel.password),
        password: this.loginModel.password,
        channel: 0
      }).then(function (ret) {
        localStorage.setItem('access_token', ret);
        _this.updateUser(ret);
        // 取到原页面路径
        var url = _this.$route.query.redirect;
        // 跳转回原页面
        _this.$router.push({
          path: decodeURIComponent(url ? url : '/m')
        });
      })["catch"](function (err) {
        console.log('login err', err);
      });
    }
  }),
  mounted: function mounted() {}
};