import {createRouter, createWebHashHistory} from 'vue-router'
import LoginView from '../views/LoginView.vue'
import UserManageView from "@/views/manager/UserManageView";
import ManagerView from "@/views/ManagerView";
import VipManageView from "@/views/manager/VipManageView";
import ResourceManageView from "@/views/manager/ResourceManageView";


const routes = [
    {
        path: "/",
        name: "manager",
        component: ManagerView,
        redirect: "/m/user/list",
        children: [
            {
                path: "/m/user/list",
                component: UserManageView
            },
            {
                path: "/m/vip/list",
                component: VipManageView
            },
            {
                path: "/m/resource/list",
                component: ResourceManageView
            },
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            auth: false
        }
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
