export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      "default": ''
    },
    size: {
      type: String,
      "default": '14px'
    }
  },
  computed: {
    iconName: function iconName() {
      return "#icon-".concat(this.iconClass);
    },
    svgClass: function svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className;
      } else {
        return 'svg-icon';
      }
    }
  }
};