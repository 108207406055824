import _defineProperty from "/root/workspace/cambridge-admin_qoOL/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import ButtonDialog from "@/components/ButtonDialog";
import api from "@/api";
export default {
  name: "VipManageView",
  components: {
    ButtonDialog: ButtonDialog,
    BatterTableDataView: BatterTableDataView
  },
  data: function data() {
    return {
      search: null
    };
  },
  methods: {
    changeVip: function changeVip(uid, type, state) {
      var _this = this;
      api.updateVip(_defineProperty({
        id: uid
      }, type, state)).then(function () {
        _this.$refs.vipTable.fetchData();
      });
    },
    init: function init() {}
  },
  mounted: function mounted() {
    this.init();
  }
};