import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3a1788e2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["xlink:href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    "class": _normalizeClass($options.svgClass),
    "aria-hidden": "true",
    style: _normalizeStyle({
      width: $props.size,
      height: $props.size
    })
  }, [_createElementVNode("use", {
    "xlink:href": $options.iconName
  }, null, 8, _hoisted_1)], 6);
}