import _createForOfIteratorHelper from "/root/workspace/cambridge-admin_qoOL/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.substr.js";
import "core-js/modules/es.array.last-index-of.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.timers.js";
import api from "@/api";
import { ElMessage } from "element-plus";
export default {
  name: "DialogView",
  data: function data() {
    return {
      dialogVisible: false,
      detail: {},
      form: {},
      video_meta: {},
      eventActions: {},
      dataSource: {},
      dataSourceMap: {},
      clearKeys: {},
      ossClient: null,
      ossInitState: false,
      uploading: false,
      ossDomain: null,
      ossFolder: null
    };
  },
  props: {
    visibleControl: {
      type: Boolean,
      "default": false
    },
    dataId: {
      type: Number,
      "default": 0
    },
    bindId: {
      type: Number,
      "default": -1
    },
    formOverrideData: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  watch: {
    visibleControl: function visibleControl(b) {
      this.dialogVisible = b;
      if (b) {
        this.render();
      }
    },
    formOverrideData: function formOverrideData(o) {
      for (var k in o) {
        this.form[k] = o[k];
      }
    }
  },
  methods: {
    handlePlaceSelect: function handlePlaceSelect(item) {
      this.$emit('onPlaceSelected', item);
    },
    queryPlaceAsync: function queryPlaceAsync(queryString, done) {
      api.queryPlace({
        search: queryString
      }).then(function (ret) {
        var _iterator = _createForOfIteratorHelper(ret),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var r = _step.value;
            r.value = r.name;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        done(ret);
      });
    },
    onLoadedVideoMetadata: function onLoadedVideoMetadata(e, file) {
      var url = file.response == null ? file.url : file.response.url;
      this.video_meta[new URL(url).pathname] = {
        'width': e.target.videoWidth,
        'height': e.target.videoHeight,
        'duration': e.target.duration
      };
    },
    isMp4: function isMp4(file) {
      if (file.response != null) {
        return file.response.url.endsWith('mp4');
      }
      return file.url.endsWith('mp4');
    },
    isImage: function isImage(file) {
      var url = file.response == null ? file.url : file.response.url;
      return url.endsWith('jpg') || url.endsWith('jpeg') || url.endsWith('png') || url.endsWith('svg') || url.endsWith('gif') || url.endsWith('bmp');
    },
    fnLazyLoad: function fnLazyLoad(itemKey, source, node, resolve) {
      var query = {};
      if (node.level > 0) {
        query[itemKey] = node.value;
      }
      api.get(source, query).then(function (ret) {
        resolve(ret);
      });
    },
    initOssClint: function initOssClint() {
      var _this = this;
      if (this.ossInitState) {
        console.log('OSS已经完成初始化!');
        return;
      }
      this.ossInitState = true;
      var OSS = require('ali-oss');
      api.getOssKeySecret().then(function (cfg) {
        _this.ossClient = new OSS({
          region: cfg.region,
          accessKeyId: cfg.accessKeyId,
          accessKeySecret: cfg.accessKeySecret,
          stsToken: cfg.securityToken,
          bucket: cfg.bucket,
          endpoint: cfg.endpoint,
          secure: true
        });
        _this.ossDomain = cfg.domain;
        _this.ossFolder = cfg.folder;
      });
    },
    beforeImageUpload: function beforeImageUpload(file, suffixLimit) {
      if (!suffixLimit) {
        return file != null;
      }
      var check = false;
      var _iterator2 = _createForOfIteratorHelper(suffixLimit),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var sf = _step2.value;
          if (file.name.endsWith(sf)) {
            check = true;
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      if (!check) {
        ElMessage.error('仅支持' + suffixLimit.join('、') + '格式');
      }
      return check;
    },
    handleRemove: function handleRemove(file, formKey) {
      var url = file.response == null ? file.url : file.response.url;
      console.log('deleting url', url);
      console.log('form', this.form);
      if (this.form[formKey + '_init'] != null) {
        console.log('deleting', formKey + '_init');
        this.form[formKey + '_init'] = this.form[formKey + '_init'].filter(function (itm) {
          return itm.url !== url;
        });
      }
      if (this.form[formKey] != null) {
        console.log('deleting', formKey);
        this.form[formKey] = this.form[formKey].filter(function (itm) {
          return itm !== url;
        });
      }
      this.showUploader(formKey);
    },
    handleImageUploadSuccess: function handleImageUploadSuccess(formKey, url, r, limit) {
      if (this.ossDomain) {
        url = this.ossDomain + '/' + r.name;
      }
      this.uploading = false;
      if (this.form[formKey]) {
        this.form[formKey].push(url);
      } else {
        this.form[formKey] = [url];
      }
      if (this.form[formKey].length >= limit) {
        this.hideUploader(formKey);
      }
    },
    hideUploader: function hideUploader(containerId) {
      var container = document.querySelector('#' + containerId);
      var elements = container.querySelectorAll('.el-upload--picture-card');
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = 'none';
      }
    },
    showUploader: function showUploader(containerId) {
      var container = document.querySelector('#' + containerId);
      var elements = container.querySelectorAll('.el-upload--picture-card');
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = 'inline-flex';
      }
    },
    handleUploadImageRemove: function handleUploadImageRemove(formKey, d) {
      var fileUrl = d.response ? d.response.url : d.url;
      var filtered = [];
      var _iterator3 = _createForOfIteratorHelper(this.form[formKey]),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var v = _step3.value;
          if (v !== fileUrl) {
            filtered.push(v);
          }
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
      this.form[formKey] = filtered;
      this.showUploader(formKey);
    },
    guid: function guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
    },
    uploadRequest: function uploadRequest(options) {
      try {
        this.uploading = true;
        var file = options.file; // 拿到 file
        var fileType = file.name.substr(file.name.lastIndexOf('.'));
        var fileName = (this.ossFolder ? this.ossFolder + '/' : 'user-upload/') + this.guid() + fileType;
        // 上传文件,这里是上传到OSS的 uploads文件夹下
        this.ossClient.put(fileName, file).then(function (res) {
          if (res.res.statusCode === 200) {
            options.onSuccess(res);
          } else {
            options.onError("上传失败");
          }
        });
      } catch (e) {
        options.onError("上传失败");
      }
    },
    queryDataSource: function queryDataSource(dataKey) {
      var _this2 = this;
      if (dataKey && this.clearKeys[dataKey]) {
        //需要清除其他key的情形
        var _iterator4 = _createForOfIteratorHelper(this.clearKeys[dataKey]),
          _step4;
        try {
          for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
            var cl = _step4.value;
            this.form[cl] = null;
          }
        } catch (err) {
          _iterator4.e(err);
        } finally {
          _iterator4.f();
        }
        this.$emit('formDataChange', {
          'key': dataKey,
          'value': this.form[dataKey]
        });
      }
      var _loop = function _loop() {
        var k = _Object$keys[_i];
        if (dataKey && dataKey === k) {
          //当前key不更新
          return "continue";
        }
        var query = {};
        for (var _i2 = 0, _Object$keys2 = Object.keys(_this2.form); _i2 < _Object$keys2.length; _i2++) {
          var _k = _Object$keys2[_i2];
          if (_this2.form[_k] != null && !(_this2.form[_k] instanceof Array)) {
            query[_k] = _this2.form[_k];
          }
        }
        api.get(_this2.dataSourceMap[k], query).then(function (ret) {
          _this2.dataSource[k] = ret;
        });
      };
      for (var _i = 0, _Object$keys = Object.keys(this.dataSourceMap); _i < _Object$keys.length; _i++) {
        var _ret = _loop();
        if (_ret === "continue") continue;
      }
    },
    onDialogClose: function onDialogClose() {
      this.$emit('onDialogClose');
      this.$emit('close');
      this.detail = {};
      this.form = {};
    },
    onButtonClick: function onButtonClick(action) {
      if (action === 'submit') {
        this.form['videoMeta'] = this.video_meta;
        this.$emit('submit', this.form);
      } else {
        this.onDialogClose();
      }
    },
    render: function render() {
      var _this3 = this;
      if (this.dataId <= 0 || !this.visibleControl) {
        return;
      }
      api.getDialogForm(this.dataId, this.bindId).then(function (ret) {
        _this3.detail = ret;
        var _iterator5 = _createForOfIteratorHelper(ret.details),
          _step5;
        try {
          for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
            var part = _step5.value;
            var _iterator6 = _createForOfIteratorHelper(part.items),
              _step6;
            try {
              var _loop2 = function _loop2() {
                var d = _step6.value;
                if (d['type'] === 'cascader' || d['type'] === 'select' || d['type'] === 'radio' || d['type'] === 'multiSelect') {
                  _this3.form[d['name']] = d['selected'];
                } else if (d['type'] === 'tree') {
                  _this3.form[d['name']] = d['selected'];
                  _this3.eventActions[d['name']] = function (checkedNodes, checkedKeys) {
                    _this3.form[d['name']] = checkedKeys.checkedKeys;
                  };
                } else if (d['type'] === 'uploadImage') {
                  _this3.initOssClint();
                  var fileList = [];
                  var files = [];
                  if (d['value']) {
                    //有上传列表预览
                    var _iterator7 = _createForOfIteratorHelper(d['value']),
                      _step7;
                    try {
                      for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
                        var g = _step7.value;
                        if (g.imageUrl) {
                          fileList.push({
                            name: g.id,
                            url: g.imageUrl
                          });
                          files.push(g.imageUrl);
                        }
                      }
                    } catch (err) {
                      _iterator7.e(err);
                    } finally {
                      _iterator7.f();
                    }
                  }
                  _this3.form[d['name']] = files;
                  _this3.form[d['name'] + '_init'] = fileList;
                  if (files.length >= d.limit) {
                    setTimeout(function () {
                      _this3.hideUploader(d['name']);
                    }, 500);
                  }
                } else {
                  _this3.form[d['name']] = d['value'];
                }
                if (d['dataSource']) {
                  _this3.dataSourceMap[d.name] = d.dataSource;
                }
                if (d['clearKeys']) {
                  _this3.clearKeys[d.name] = d.clearKeys;
                }
              };
              for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
                _loop2();
              }
            } catch (err) {
              _iterator6.e(err);
            } finally {
              _iterator6.f();
            }
          }
        } catch (err) {
          _iterator5.e(err);
        } finally {
          _iterator5.f();
        }
        _this3.queryDataSource();
      });
    }
  },
  mounted: function mounted() {
    this.render();
  }
};