import api from "@/api";
import AsideMenu from "@/components/AsideMenu";
export default {
  name: "HomeView",
  components: {
    AsideMenu: AsideMenu
  },
  data: function data() {
    return {
      tab: 0,
      user: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    api.getUserInfo().then(function (ret) {
      _this.user = ret;
    });
  },
  methods: {
    logout: function logout() {
      localStorage.removeItem('access_token');
      // api.logout().then(() => {
      this.$router.go(0);
      // })
    }
  }
};