import {createStore} from 'vuex'

export default createStore({
    state: {
        user: {},
        dashboard: {
            activeTab: '代码计算',
            tabIndex: 0,
            subIndex: 0,
        },
        examLeftTime:'-',
    },
    getters: {},
    mutations: {
        updateExamLeftTime(state,lt){
            state.examLeftTime = lt;
        },
        updateUser(state, val) {
            state.user = val;
        },
        updateDashboard(state,setting){
            state.dashboard = setting
        }
    },
    actions: {},
    modules: {}
})
