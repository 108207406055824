import _defineProperty from "/root/workspace/cambridge-admin_qoOL/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
export default {
  name: "UserManageView",
  components: {
    BatterTableDataView: BatterTableDataView
  },
  data: function data() {
    return {
      search: null
    };
  },
  methods: {
    changeVip: function changeVip(uid, type, state) {
      var _this = this;
      api.updateUserVip(_defineProperty({
        id: uid
      }, type, state)).then(function () {
        _this.$refs.userTable.fetchData();
      });
    },
    init: function init() {}
  },
  mounted: function mounted() {
    this.init();
  }
};