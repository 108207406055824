import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import DialogView from "@/components/DialogView";
import path from "@/api/path";
import api from "@/api";
import { ElLoading } from "element-plus";
export default {
  name: "ButtonDialog",
  components: {
    DialogView: DialogView
  },
  props: {
    style: {
      type: Number,
      "default": 0
    },
    btnId: {
      type: Number,
      "default": 0
    },
    bindId: {
      type: Number,
      "default": null
    },
    btnName: {
      type: String,
      "default": '新增'
    },
    size: {
      type: String,
      "default": 'normal'
    },
    initData: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      dialogId: 0,
      submitUrl: '',
      btnVisible: false
    };
  },
  methods: {
    onSubmit: function onSubmit(d) {
      var _this = this;
      var loading = ElLoading.service({
        lock: true,
        text: '请稍等...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      for (var _i = 0, _Object$keys = Object.keys(this.initData); _i < _Object$keys.length; _i++) {
        var k = _Object$keys[_i];
        d[k] = this.initData[k];
      }
      api.post(this.submitUrl, d).then(function () {
        _this.btnVisible = false;
        _this.$emit('fresh');
      })["finally"](function () {
        loading.close();
      });
    },
    init: function init() {
      this.dialogId = path.simpleBtn[this.btnId].dialogId;
      this.submitUrl = path.simpleBtn[this.btnId].submitUrl;
    }
  },
  mounted: function mounted() {
    this.init();
  }
};