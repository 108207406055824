const base = {
    baseUrl: "",
    menus: "/manager/menu/menuList",
    userInfo: "/manage/info/my",
    loginCaptchaToken: "/login/captchaToken",
    login: "/m/accountLogin",
    logout: "/manage/logout?channel=0",


    getOssKeySecret: "/manage/oss/getOssKeySecret",
    updateVip: "/manage/vip/update",
    updateUserVip: "/manage/user/update",


    queryForm: {
        1: '/manager/class/plan/getQueryForm'
    },
    dialogs: {
        1: '/manage/vip/getCreateForm',
        2: '/manage/vip/getUploadForm',
        3: '/manage/data/resource/getUpdateForm?id={0}',
        4: '/manage/data/resource/getCreateForm',
    },
    simpleBtn: {
        1: {
            dialogId: 1,
            submitUrl: '/manage/vip/create',
        },
        2: {
            dialogId: 2,
            submitUrl: '/manage/vip/importFromXlsx',
        },
        3: {
            dialogId: 4,
            submitUrl: '/manage/data/resource/create',
        },
    },
    table: {
        1: {
            list: '/manage/user/userList',
            delete:'/manage/user/delete',
            deleteKey:'id'
        },
        2: {
            list: '/manage/vip/list',
        },
        3: {
            list: '/manage/data/resource/list',
            update: '/manage/data/resource/update',
            updateKey: 'id',
            updateDialog: 3,
            delete: '/manage/data/resource/delete',
            deleteKey: 'id',
        },
    }

}

export default base;