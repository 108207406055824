import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import DynaQueryForm from "@/components/DynaQueryForm";
import DialogView from "@/components/DialogView";
import path from "@/api/path";
import api from "@/api";
export default {
  name: "BatterTableDataView",
  components: {
    DialogView: DialogView,
    DynaQueryForm: DynaQueryForm
  },
  setup: function setup() {
    return {
      locale: zhCn
    };
  },
  props: {
    maxPageCnt: {
      type: Number,
      "default": 7
    },
    highlightCurrentRow: {
      type: Boolean,
      "default": false
    },
    pageSizeChoices: {
      type: Array,
      "default": function _default() {
        return [10, 20, 30, 40, 50, 100];
      }
    },
    tableId: {
      type: Number,
      "default": 0
    },
    initQuery: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      createDialogVisible: false,
      editDialogVisible: false,
      detailDialogVisible: false,
      editBindId: 0,
      detailBindId: 0,
      query: {},
      form: {},
      total: 0,
      page: 1,
      pageSize: 10,
      tableDataLoading: false,
      tableData: {
        title: "...",
        header: [],
        list: [],
        textStyleMapping: {},
        textMapping: {}
      },
      tableParams: {
        queryForm: 0,
        list: null,
        create: null,
        update: null,
        "delete": null,
        detailDialog: 0,
        createDialog: 0,
        updateDialog: 0,
        updateKey: null,
        detailKey: null,
        deleteKey: null
      },
      queryFormVersion: 0,
      queryFormHeight: 'auto'
    };
  },
  watch: {
    initQuery: function initQuery() {
      console.log('onInitQueryChange', this.initQuery);
      this.fetchData();
      // this.$emit('onInitQuery', this.initQuery);
    }
  },

  methods: {
    indexValueMethod: function indexValueMethod(i) {
      return (this.page - 1) * this.pageSize + i + 1;
    },
    initParams: function initParams() {
      this.pageSize = this.pageSizeChoices[0];
      this.tableParams = path.table[this.tableId];
    },
    createData: function createData(data) {
      var _this = this;
      for (var _i = 0, _Object$keys = Object.keys(this.initQuery); _i < _Object$keys.length; _i++) {
        var k = _Object$keys[_i];
        if (this.initQuery[k] != null) {
          data[k] = this.initQuery[k];
        }
      }
      api.post(this.tableParams.create, data).then(function () {
        _this.createDialogVisible = false;
        _this.createDialogDataId = 0;
        _this.fetchData();
      });
    },
    updateData: function updateData(data) {
      var _this2 = this;
      data[this.tableParams.updateKey] = this.editBindId;
      api.post(this.tableParams.update, data).then(function () {
        _this2.editDialogVisible = false;
        _this2.editBindId = 0;
        _this2.fetchData();
      });
    },
    addMore: function addMore() {
      if (this.tableParams.create && this.tableParams.createDialog) {
        this.createDialogVisible = true;
      } else {
        this.$emit('addMore');
      }
    },
    onEditData: function onEditData(data) {
      if (this.tableParams.update) {
        this.editDialogVisible = true;
        this.editBindId = data['id'];
      } else {
        this.$emit('onEditData', data);
      }
    },
    onDetailData: function onDetailData(data) {
      if (this.tableParams.detailKey) {
        this.detailDialogVisible = true;
        this.detailBindId = data['id'];
      } else {
        this.$emit('onDetailData', data);
      }
    },
    onDeleteData: function onDeleteData(data) {
      var _this3 = this;
      var deleteQuery = {};
      deleteQuery[this.tableParams.deleteKey] = data['id'];
      api.get(this.tableParams["delete"], deleteQuery).then(function () {
        _this3.fetchData();
      });
    },
    gotoPage: function gotoPage(e) {
      this.page = e;
      this.fetchData();
    },
    onSizeChange: function onSizeChange(e) {
      this.page = 1;
      this.pageSize = e;
      this.fetchData();
    },
    onQuery: function onQuery(data) {
      this.query = data;
      this.fetchData();
      this.$emit('onQuery', data);
    },
    onSortChange: function onSortChange(data) {
      this.query.orderBy = data.prop;
      this.query.orderDesc = data.order !== 'ascending';
      this.fetchData();
      this.$emit('onQuery', data);
    },
    onResetQuery: function onResetQuery() {
      this.queryFormHeight = "".concat(this.$refs.queryFormContainer.clientHeight, "px");
      this.queryFormVersion++;
      this.query = {};
      this.$refs.icetable.clearSort();
      this.fetchData();
      this.$emit('onResetQuery', {});
    },
    fetchData: function fetchData() {
      var _this4 = this;
      this.tableDataLoading = true;
      for (var _i2 = 0, _Object$keys2 = Object.keys(this.initQuery); _i2 < _Object$keys2.length; _i2++) {
        var k = _Object$keys2[_i2];
        if (this.initQuery[k] != null) {
          this.query[k] = this.initQuery[k];
        }
      }
      this.query['page'] = this.page - 1;
      this.query['pageSize'] = this.pageSize;
      if (this.tableParams.listMethod === 'post') {
        api.post(this.tableParams.list, this.query).then(function (ret) {
          _this4.tableDataLoading = false;
          _this4.tableData = ret;
          _this4.total = ret.total;
          _this4.page = ret.page + 1;
          _this4.pageSize = ret.pageSize;
          _this4.$emit('onFetchData', ret);
        })["catch"](function () {
          _this4.tableDataLoading = false;
        });
      } else {
        api.get(this.tableParams.list, this.query).then(function (ret) {
          _this4.tableDataLoading = false;
          _this4.tableData = ret;
          _this4.total = ret.total;
          _this4.page = ret.page + 1;
          _this4.pageSize = ret.pageSize;
          _this4.$emit('onFetchData', ret);
        })["catch"](function () {
          _this4.tableDataLoading = false;
        });
      }
    }
  },
  mounted: function mounted() {
    this.initParams();
    this.fetchData();
    this.$emit('onInitQuery', this.initQuery);
  }
};